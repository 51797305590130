//
// Custom style
//

.header-bunner{
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;

  .text-information{
    margin-bottom: 4rem;
    font-size: 1.2rem;
    font-weight: 300;
    color: $white;
  }
}

.payment-block{
  background: linear-gradient(to bottom, #dcc194, #ffedc7);
  border-bottom: 5px solid #dabf91;

  .payment-button{
    margin-right: 3px;
    &.active,
    &:active,
    &:focus,
    &.focus{
      color: $white;
      background-color: $pink;
    }
  }

  .payment-input{
    border: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

  }

}

.children-slide{
  padding: 4rem;
  background-color: $gray-200;
  border-radius: 7px;

  .need-sum{
    width: max-content;
    margin-top: 1rem;
    border-top: 3px solid $danger;
  }
  .carousel-indicators{
    bottom: 0;
    justify-content: flex-end;
    margin-bottom: 0;
    .active,
    :hover{
      background-color: $danger;
      border: 3px solid $white;
    }

  }
  .carousel-indicators li{
    position: relative;
    flex: 0 1 auto;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    margin-left: 8px;
    text-indent: -999px;
    cursor: pointer;
    background-color: $white;
    border: 1px solid $white;
    border-radius: 10px;

  }
}

.more-link::after{
  padding-left: .5rem;
  text-decoration: none;
  content: ">";
}


.bg-footer{
  background-color: #ebebeb;
}

.project-slide{
  padding: 4rem;
  margin-bottom: 3rem;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 7px;

  .blockquote{
    position: relative;
    margin: 0;
    font-size: 1.7rem;
    line-height: 1.4;
    &::before{
      position: absolute;
      top: 1rem;
      left: -1rem;
      content: "«";
    }
    &::after{
      margin-left: .2rem;
      content: "»";
    }
  }

}

html {
  font-size: .8rem;
}

@include media-breakpoint-up(md) {
  html {
    font-size: .8rem;
  }
}

@include media-breakpoint-up(lg) {
  html {
    font-size: 1rem;
  }
}
